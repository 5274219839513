<template>
    <div id="download-ticket">
        <div class="image-container">
            <div class="image"></div>
        </div>
        <template v-if="errorMessage == ''">
            <div class="title">{{ $t('public.bill.title2') }}</div>
            <div class="text">{{ $t('public.bill.text') }}</div>
        </template>
        <template v-else>
            <Message type="error" :msg="errorMessage"></Message>
        </template>
    </div>
</template>

<script>
import Message from '@/components/ui/Message.vue'

export default {
    name: 'DownloadTicket',
    components: {
        Message
    },
    data() {
        return {
            errorMessage: ''
        }
    },
    created() {
        const self = this
        const bill = this.$route.params.id
        this.$store
            .dispatch('form/downloadCorrectiveBill', { bill: bill })
            .then(function (response) {
                if (response.status) {
                    const pdf = response.data
                    const name = pdf.substr(pdf.lastIndexOf('/') + 1)
                    const a = document.createElement('a')
                    a.href = pdf
                    a.download = name
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                } else {
                    self.errorMessage = response.error.msg
                }
            })
            .catch(function () {
                self.errorMessage = self.$t('public.errors.api')
            })
    }
}
</script>

<style lang="scss">
#download-ticket {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 100px;

    .image-container {
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;

        .image {
            height: 100px;
            width: 100px;
            background-image: url('../../public/img/plane.png');
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    .title {
        width: 100%;
        text-align: center;
        font-family: Speedee-bd;
        font-size: 30px;
        margin-bottom: 40px;
    }

    .text {
        width: 100%;
        text-align: center;
        font-family: Speedee;
        font-size: 30px;
    }
}
</style>
